import { browserVersion, userAgent, Vendor } from '@/helpers/vendor';

export function useMatchSupportedBrowser(): boolean {
  if (userAgent === Vendor.firefox) return true;

  if (userAgent === Vendor.mobile) {
    // doesn't support safari ios below 15
    const [major] = browserVersion.split('.');

    return Number(major) < 15;
  }

  return false;
}
