import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, createElementVNode as _createElementVNode, renderList as _renderList, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c677d8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-list" }
const _hoisted_2 = { class: "text-wrap" }
const _hoisted_3 = {
  key: 0,
  class: "users-wrap"
}
const _hoisted_4 = { class: "avatar-wrap" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "username-list" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 1,
  class: "expanded-user-list"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typography = _resolveComponent("Typography")!
  const _component_GuestAvatarMock = _resolveComponent("GuestAvatarMock")!
  const _component_ContactItem = _resolveComponent("ContactItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Typography, { class: "title" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('settings.userList.title')), 1)
        ]),
        _: 1
      }),
      (_ctx.numberOfUserOverflows)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.displayedUsernames?.length && !_ctx.isOpenUsers)
              ? (_openBlock(), _createBlock(_component_Typography, {
                  key: 0,
                  class: "link",
                  fontType: "link",
                  onClick: _ctx.showAllUsers
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('settings.userList.button')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "close",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showAllUsers && _ctx.showAllUsers(...args)))
                }))
          ], 64))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.isOpenUsers)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.displayedUsernames?.length)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedUserAvatar, (userInfo, index) => {
                    return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                      (userInfo.avatar)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            class: "avatar",
                            style: _normalizeStyle({'left': `${index * 32.5}px`}),
                            src: userInfo.avatar
                          }, null, 12, _hoisted_5))
                        : (_openBlock(), _createBlock(_component_GuestAvatarMock, {
                            key: 1,
                            "user-name": userInfo.displayName,
                            style: _normalizeStyle({'left': `${index * 32.5}px`})
                          }, null, 8, ["user-name", "style"]))
                    ], 64))
                  }), 128))
                ]),
                _createElementVNode("div", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedUsernames, (name, index) => {
                    return (_openBlock(), _createBlock(_component_Typography, {
                      class: "username",
                      key: index
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getUserName(name, index)), 1)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128)),
                  (!_ctx.isMobileScreen)
                    ? (_openBlock(), _createElementBlock("br", _hoisted_7))
                    : _createCommentVNode("", true),
                  (_ctx.numberOfUserOverflows)
                    ? (_openBlock(), _createBlock(_component_Typography, {
                        key: 1,
                        class: "second-text"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("and " + _toDisplayString(_ctx.numberOfUserOverflows) + " " + _toDisplayString(_ctx.numberOfUserOverflows > 1 ? 'others' : 'other'), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ])
              ], 64))
            : (_openBlock(), _createBlock(_component_Typography, {
                key: 1,
                class: "second-text"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('settings.userList.empty')), 1)
                ]),
                _: 1
              }))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeUsersInConference, (user) => {
            return (_openBlock(), _createBlock(_component_ContactItem, {
              item: user.username,
              key: user.id,
              "display-name": user.display_name,
              userName: user.username,
              endpointId: user.id,
              avatar: user.picture
            }, null, 8, ["item", "display-name", "userName", "endpointId", "avatar"]))
          }), 128))
        ]))
  ]))
}