import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-368af07e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "item-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typography = _resolveComponent("Typography")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Typography, {
        class: "drawer-title",
        fontType: "text"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.drawerTitle), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_Button, {
        class: "done-btn",
        size: "m",
        mode: "flat",
        onClick: _ctx.closeDrawer
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('done')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.devicesList, (device) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["choose-devices", {'active': _ctx.isActive(device)}]),
        onClick: ($event: any) => (_ctx.chooseDevice(device))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Typography, {
            class: "item-title",
            fontType: "text"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(device.label), 1)
            ]),
            _: 2
          }, 1024),
          (_ctx.isActive(device))
            ? (_openBlock(), _createBlock(_component_Icon, {
                key: 0,
                class: "chevron",
                spriteUrl: "/image/videoconf-icons.svg",
                name: "ic24-checkMark",
                color: "--sui-purple-500"
              }))
            : _createCommentVNode("", true)
        ])
      ], 10, _hoisted_2))
    }), 256))
  ], 64))
}