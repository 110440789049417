
  import { computed, defineComponent } from 'vue';
  import { useStore } from 'effector-vue/composition';
  import { Icon, Button } from '@voximplant/spaceui';
  import { useIsMobileScreen } from '@/hooks/isMobile';
  import { useI18n } from 'vue-i18n';
  import Tooltip from '@/components/info/Tooltip.vue';
  import { meetingStore } from '@/store/meeting';
  import { toggleAudioEvent, $devices } from '@/store/devices/index';
  import {
    $mirrorStore,
    toggleMirrorAudioStream,
    toggleMirrorVideoStream,
  } from '@/store/mirrorMedia/index';
  import { toggleDrawer } from '@/store/drawer';
  import { soundLevel } from '@/helpers/audioWorklet';

  export default defineComponent({
    name: 'ButtonPanel',
    components: {
      Tooltip,
      Icon,
      Button,
    },
    props: {
      link: {
        type: String,
        default: '/image/videoconf-icons.svg',
        required: false,
      },
      classes: {
        type: String,
        default: 'disabled',
        required: false,
      },
      settingsIcon: {
        type: String,
        default: 'ic25-settings',
        required: false,
      },
      micIcon: {
        type: String,
        default: 'ic25-mic-on',
        required: false,
      },
      videoIcon: {
        type: String,
        default: 'ic25-video-on',
        required: false,
      },
      moreIcon: {
        type: String,
        default: 'ic25-more',
        required: false,
      },
      layoutsIcon: {
        type: String,
        default: 'ic25-layouts',
        required: false,
      },
      portraitIcon: {
        type: String,
        default: 'ic25-portrait-on',
        required: false,
      },
      deviceError: {
        type: String,
        default: '',
        required: false,
      },
    },
    setup(props) {
      const { isMobileScreen } = useIsMobileScreen();
      const { t } = useI18n();
      const meeting = useStore(meetingStore);
      const devices = useStore($devices);
      const mirrorStore = useStore($mirrorStore);
      const isAudioDisabled = computed(
        () => !!props.deviceError && props.deviceError !== 'noCamera'
      );

      const iconCamName = computed(() =>
        mirrorStore.value.videoPreview ? 'ic25-video-on' : 'ic25-video-off'
      );

      const iconMicName = computed(() =>
        devices.value.audioEnabled ? `ic25-mic-on-${soundLevel.value}` : 'ic25-mic-off'
      );

      const toggleAudio = () => {
        toggleAudioEvent();
        toggleMirrorAudioStream();
      };

      const toggleVideo = () => {
        toggleMirrorVideoStream();
      };

      return {
        isAudioDisabled,
        devices,
        isMobileScreen,
        t,
        meeting,
        iconMicName,
        iconCamName,
        toggleAudio,
        toggleVideo,
        toggleDrawer,
      };
    },
  });
