import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-334bf886"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "expanded-settings" }
const _hoisted_2 = { class: "form-settings" }
const _hoisted_3 = { class: "microphone-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!
  const _component_Sound = _resolveComponent("Sound")!
  const _component_TogglePanel = _resolveComponent("TogglePanel")!
  const _component_FileInput = _resolveComponent("FileInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Select, {
          class: "select microphone",
          modelValue: _ctx.devices.selectedAudioDevice,
          "onUpdate:modelValue": _ctx.updateAudioDevice,
          label: _ctx.t('settings.microphone'),
          options: _ctx.devices.audioDevices,
          key: _ctx.devices.audioDevices,
          searchable: false,
          placeholder: "None",
          disabled: _ctx.isAudioDisabled,
          id: "microphone"
        }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "options", "disabled"]),
        _createVNode(_component_Sound)
      ]),
      (_ctx.canUseOutputDevice())
        ? (_openBlock(), _createBlock(_component_Select, {
            class: "select",
            modelValue: _ctx.devices.selectedSpeakerDevices,
            "onUpdate:modelValue": _ctx.updateSpeakerDevice,
            label: _ctx.t('settings.speaker'),
            options: _ctx.devices.speakerDevices,
            key: _ctx.devices.speakerDevices,
            searchable: false,
            placeholder: "None",
            disabled: !_ctx.devices.speakerDevices
          }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "options", "disabled"]))
        : _createCommentVNode("", true),
      _createVNode(_component_Select, {
        class: "select camera",
        modelValue: _ctx.devices.selectedVideoDevice,
        "onUpdate:modelValue": _ctx.updateVideoDevice,
        label: _ctx.t('settings.camera'),
        options: _ctx.devices.videoDevices,
        key: _ctx.devices.videoDevices,
        searchable: false,
        placeholder: "None",
        disabled: !_ctx.devices.requestDone || _ctx.devices.videoDisabled
      }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "options", "disabled"]),
      _createVNode(_component_Select, {
        class: "select quality",
        modelValue: _ctx.devices.selectedQuality,
        "onUpdate:modelValue": _ctx.updateVideoQuality,
        label: _ctx.t('settings.quality'),
        options: _ctx.videoQuality,
        searchable: false,
        placeholder: "None",
        disabled: !_ctx.devices.requestDone || _ctx.devices.videoDisabled
      }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "options", "disabled"]),
      (_ctx.meeting.owner&&false)
        ? (_openBlock(), _createBlock(_component_TogglePanel, {
            key: 1,
            class: "toggles"
          }))
        : _createCommentVNode("", true),
      (_ctx.meeting.owner&&false)
        ? (_openBlock(), _createBlock(_component_FileInput, {
            key: 2,
            isDefault: false
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}