
  import { computed, defineComponent } from 'vue';
  import { useStore } from 'effector-vue/composition';
  import { $devices, setActiveDevices } from '@/store/devices';
  import { Icon, Typography, Button } from '@voximplant/spaceui';
  import { AudioDeviceInfo } from '@/store/devices/AudioDeviceInfo';
  import { $mirrorStore, requestMirrorStream } from '@/store/mirrorMedia';
  import { VideoDeviceInfo } from '@/store/devices/VideoDeviceInfo';
  import { useI18n } from 'vue-i18n';
  import { closeDrawer } from '@/store/drawer';

  export default defineComponent({
    name: 'ChooseDevices',
    components: {
      Typography,
      Icon,
      Button,
    },
    props: {
      deviceType: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const { t } = useI18n();
      const devices = useStore($devices);
      const mirrorStore = useStore($mirrorStore);
      const selectedType = computed(() =>
        props.deviceType.includes('audio') ? 'selectedAudioDevice' : 'selectedVideoDevice'
      );
      const drawerTitle = computed(() =>
        props.deviceType.includes('audio') ? t('settings.audioDevices') : t('settings.camera')
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const devicesList = devices.value[props.deviceType];
      const isActive = (item: AudioDeviceInfo | never) => {
        return item.value === devices.value[selectedType.value]?.value;
      };
      const chooseDevice = (selectDevice: VideoDeviceInfo | AudioDeviceInfo | any) => {
        if (props.deviceType.includes('video')) {
          if (mirrorStore.value.videoPreview) {
            requestMirrorStream({
              selectedAudioDevice: $devices.getState().selectedAudioDevice,
              selectedVideoDevice: selectDevice,
            });
          } else {
            setActiveDevices({ selectedVideoDevice: selectDevice });
          }
        } else if (selectDevice) {
          requestMirrorStream({
            selectedAudioDevice: selectDevice,
            selectedVideoDevice: $devices.getState().selectedVideoDevice,
          });
        }
      };

      return {
        t,
        drawerTitle,
        devicesList,
        isActive,
        chooseDevice,
        closeDrawer,
      };
    },
  });
