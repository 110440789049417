<template lang="pug">
.users-in-the-meeting
  .header
    Button.back-btn(
      :icon="{ spriteUrl: '/image/videoconf-icons.svg', name: 'ic25-shape'}"
      size="s"
      mode="flat"
      @click="closeDrawer"
    ) {{ t('back') }}
    h2.title {{ t('settings.userList.title') }}
  .contact-list-wrap
      ContactItem(
        v-for="user in usersInCall"
        :id="user?.id"
        :displayName="user?.display_name"
        :key="user?.vox_user_id"
        :userName="user?.username"
        :endpointId="user?.vox_user_id"
        :avatar="user?.picture"
      )
</template>

<script>
  import { defineComponent } from 'vue';
  import ContactItem from './ContactItem';
  import { Button } from '@voximplant/spaceui';
  import { useI18n } from 'vue-i18n';
  import { closeDrawer } from '@/store/drawer';
  import { useStore } from 'effector-vue/composition';
  import { $usersInCall } from '@/store/users';
  import { screenInnerHeight } from '@/hooks/useWindowInnerHeight';

  export default defineComponent({
    name: 'UsersInTheMeeting',
    components: {
      Button,
      ContactItem,
    },
    setup() {
      const { t } = useI18n();
      const usersInCall = useStore($usersInCall);
      return {
        t,
        closeDrawer,
        usersInCall,
        screenInnerHeight,
      };
    },
  });
</script>

<style scoped>
  .users-in-the-meeting {
    font-family: var(--fontRoboto);
    overflow: hidden;
    height: v-bind('screenInnerHeight');

    & .header {
      display: flex;
      height: 40px;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      margin-bottom: var(--pad16);
      & .back-btn {
        position: absolute;
        left: 12px;
        top: 6px;
        &:deep(.icon-container) {
          margin: 0;
        }
      }
      & .title {
        color: var(--gray800);
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        padding: 0 var(--pad16);
        margin: auto;
      }
    }
    & .contact-list-wrap {
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      padding: var(--pad24) var(--pad16) 0 var(--pad20);
      height: calc(100% - 168px);
    }
  }
</style>
