
  import { computed, defineComponent, ref } from 'vue';
  import { Typography } from '@voximplant/spaceui';
  import { useI18n } from 'vue-i18n';
  import ContactItem from '@/components/chat/ContactItem.vue';
  import { useIsMobileScreen } from '@/hooks/isMobile';
  import { openDrawer } from '@/store/drawer';
  import { $users } from '@/store/users';
  import { useStore } from 'effector-vue/composition';
  import GuestAvatarMock from '@/components/accounts/GuestAvatarMock.vue';

  const __default__ = defineComponent({
    name: 'UserList',
    components: {
      Typography,
      ContactItem,
      GuestAvatarMock,
    },
    emits: ['openUser'],
    setup(props, { emit }) {
      const { t } = useI18n();
      const userStore = useStore($users);
      const activeUsersInConference = computed(() =>
        userStore.value?.users.filter((user) => user.is_active)
      );
      const { isMobileScreen } = useIsMobileScreen();
      const userSliceEndpoint = computed(() => (isMobileScreen.value ? 3 : 5));
      const userNameList = computed(() =>
        activeUsersInConference.value?.map((user) => user.display_name)
      );
      const userAvatarsMock = computed(() =>
        activeUsersInConference.value?.map((user) => ({
          displayName: user.display_name,
          avatar: user.picture,
        }))
      );
      const displayedUsernames = computed(() =>
        userNameList.value?.slice(0, userSliceEndpoint.value)
      );
      const displayedUserAvatar = computed(() =>
        userAvatarsMock.value?.slice(0, userSliceEndpoint.value)
      );
      const avatarWrapWidth = computed(
        () => `${displayedUserAvatar.value.length * 35}px`
      ); /* 40px avatar width - 5px gap */
      const numberOfUserOverflows = computed(
        () => userNameList.value?.slice(userSliceEndpoint.value).length
      );
      const getUserName = (name: string, index: number) => {
        return displayedUsernames.value?.length !== index + 1 ? `${name}, ` : name;
      };
      const isOpenUsers = ref(false);
      const showAllUsers = () => {
        if (isMobileScreen.value) {
          openDrawer({ section: 'userInTheMeeting' });
        } else {
          isOpenUsers.value = !isOpenUsers.value;
          emit('openUser', isOpenUsers.value);
        }
      };
      const userWrapTemplate = computed(() =>
        displayedUsernames?.value.length ? 'auto 1fr' : 'auto'
      );

      return {
        t,
        showAllUsers,
        getUserName,
        numberOfUserOverflows,
        isOpenUsers,
        isMobileScreen,
        displayedUsernames,
        displayedUserAvatar,
        activeUsersInConference,
        userWrapTemplate,
        avatarWrapWidth,
      };
    },
  });

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "692ca32d": (_ctx.userWrapTemplate),
  "3dde25a5": (_ctx.avatarWrapWidth)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__