import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-755791cf"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "info"
}
const _hoisted_2 = { class: "img-wrap" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_ctx.isShow)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Icon, {
            class: "icon",
            spriteUrl: _ctx.link,
            name: _ctx.iconName
          }, null, 8, ["spriteUrl", "name"])
        ]),
        _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.text), 1)
      ]))
    : _createCommentVNode("", true)
}