import { Message } from '@/store/chat/Message';
import { $users } from '@/store/users';
import { Conversation } from '@/store/chat/Conversation';
import { Messaging, Events } from '@voximplant/websdk/modules/messaging';
import {
  updateChatContent,
  joinChat,
  fetchInitialData,
  $chatContent,
  subscribeChat,
  clearChat,
  sendMessageToAll,
  plusNewMessage,
  $chatUsers,
  updateChatUsers,
  toggleChatLoading,
  $isChatLoading,
  updateLocalChatUser,
} from '@/store/chat/index';
import { updateReaction } from '@/store/reactions';
import { $drawer } from '@/store/drawer';
import { guard, sample } from 'effector';
import appConfig from '@/config';
import { ConferenceUser } from '@/api/conferences/structures';
import { VideoconfApi } from '@/api';
import { getDateTimeFormat } from '@/helpers/getDateTimeFormat';

const formatMessage = (message: any): Message => {
  console.log('chat store', 'formatMessage get', message);
  const senderId = message.initiator || message.message.sender;
  const allChatUsers = [...$chatUsers.getState().users, $users.getState().me];
  const chatUser = allChatUsers.find((user) => user?.chatUser.userId === senderId);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const allUsers: ConferenceUser[] = $users.getState().users;
  const currentUser = allUsers?.find((user) => user?.id === chatUser?.authUser?.authId) || {
    display_name: 'Unknown User',
    picture: '',
  };
  if (!currentUser) {
    console.error('chat store', 'formatMessage ERROR, cant find user', { senderId, allUsers });
  } else {
    console.log('chat store', 'formatMessage find user', currentUser);
  }
  const messageInfo = {
    name: currentUser?.display_name,
    time: getDateTimeFormat(message.timestamp * 1000 || message.timeStamp), // on sendMessage event 'timestamp' params get in seconds
    avatar: currentUser?.picture,
    message: message.message.text,
  };
  console.log('chat store', 'formatMessage', messageInfo);
  return messageInfo;
};

// joining an existing chat of a new user
joinChat.use(async (uuid) => {
  // https://voximplant.com/docs/references/websdk/voximplant/messaging/messenger#joinconversation
  console.log('chat store', `joinChat.use get uuid ${uuid}`);
  try {
    const conversation = await Messaging.joinConversation(uuid);
    console.log('chat store', 'joinChat.use joinConversation', conversation);
    return conversation;
  } catch (e) {
    const conversation = await Messaging.getConversation(uuid);
    console.log('chat store', `joinChat.use (catch) ${e.message}`, conversation);
    return conversation;
  }
});

fetchInitialData.use(async (currentConversation: Conversation) => {
  console.log('chat store', 'fetchInitialData.use get', currentConversation);
  const messages: Message[] = [];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  await updateChatUsers(currentConversation.participants.map((user) => user.user_id));
  const convEvents = await currentConversation?.retransmitEvents(
    Number(currentConversation?.lastSeq),
    50
  );
  convEvents?.forEach((ev: any) => {
    if (ev.messengerAction === 'sendMessage' && ev.message.text !== '') {
      messages.push(formatMessage(ev));
    }
  });
  console.log('chat store', 'fetchInitialData.use return', ...messages);
  return { messages };
});

updateChatUsers.use(
  async (usersIds): Promise<any> => {
    usersIds = usersIds.filter((id) => id !== $users.getState().me?.chatUser.userId);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const chatUsers = await Messaging.getUsersById(usersIds);
    console.log(
      'users Store',
      'updateUsers call Messaging.getUsersById usersIds without me params',
      usersIds
    );
    console.log('users Store', 'updateUsers localStorage', localStorage);
    try {
      const users = await Promise.all(
        chatUsers.map(async (user) => {
          console.log('users Store', 'updateUsers server request', {
            url: appConfig.baseServerUrl,
            userName: user?.user.userName,
          });
          // fix for guest chat account
          const { data } = await VideoconfApi.users.user(user.user.userName).getInfo();
          console.log('users Store', 'updateUsers server response', data);
          if (!data) return null;

          return {
            chatUser: user.user,
            authUser: {
              authId: data.id,
              avatar: data.picture,
            },
          };
        })
      );

      return users.filter((user) => user);
    } catch (err) {
      console.error('updateUsers return users error', err);
    }
  }
);

updateChatUsers.failData((err: unknown) => {
  console.error('users Store', err);
});

$chatContent
  .on(joinChat.doneData, (currentStore, chat) => {
    toggleChatLoading(true);
    fetchInitialData(chat);
    subscribeChat(chat);
    console.log('$chatContent', 'joinChat.doneData', {
      ...currentStore,
      currentConversation: chat,
    });
    return { ...currentStore, currentConversation: chat };
  })
  .on(fetchInitialData.doneData, (currentStore, { messages }) => {
    console.log('$chatContent', 'fetchInitialData.doneData', { ...currentStore, messages });
    return { ...currentStore, messages };
  })
  .on(fetchInitialData.finally, () => {
    toggleChatLoading(false);
  })
  .on(updateChatContent, (currentStore, newMessage) => {
    if (newMessage.message.payload && newMessage.message.payload[0]?.command === 'reaction') {
      const senderId = newMessage.initiator || newMessage.message.sender;
      const allChatUsers = [...$chatUsers.getState().users, $users.getState().me];
      const chatUser = allChatUsers.find((user) => user?.chatUser.userId === senderId);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const allUsers: ConferenceUser[] = $users.getState().users;
      const user = allUsers.find((userObj) => userObj?.id === chatUser?.authUser?.authId);
      if (!user)
        return console.warn('$chatContent', 'updateChatContent Cannot find user for a reaction', {
          allUsers,
          senderId,
        });
      else console.log('$chatContent', 'updateChatContent find user', user);
      updateReaction({
        userName: user.username,
        type: newMessage.message.payload[0].type,
        active: newMessage.message.payload[0].active,
      });
    }
    if (newMessage.message.text !== '') {
      currentStore.messages = [...(currentStore.messages || []), formatMessage(newMessage)];
    }
    console.log('$chatContent', 'updateChatContent', { ...currentStore });
    return { ...currentStore }; // возвращаем обновлённый store
  })
  .on(subscribeChat, (store, chat) => {
    // receive a new chat message
    chat.addEventListener(Events.MessageReceived, (ev) => {
      console.log('$chatContent', 'subscribeChat Events.MessageReceived', ev);
      updateChatContent(ev);
    });
    // a new user adding events
    chat.addEventListener(Events.UsersAdded, (ev) => {
      console.log('$chatContent', `subscribeChat UsersAdded with ID ${ev.usersId}`);
      updateChatUsers(ev.usersId);
    });
  })
  .on(clearChat, (store) => {
    store.currentConversation?.removeEventListener(Events.MessageReceived);
    console.log('$chatContent', 'clearChat');
    return {};
  })
  .on(sendMessageToAll, (store, { text, customData }) => {
    console.log('$chatContent', 'sendMessageToAll', { text, customData });
    store.currentConversation?.sendMessage(text, customData || []);
  });

$chatUsers.on(updateChatUsers.doneData, (store, users) => {
  console.log('$users', 'updateUsers.doneData get users', users);
  store.users = store.users ? [...store.users, ...users] : users;
  console.log('$users', 'updateUsers.doneData return', store.users);
  return { ...store };
});

$isChatLoading.on(toggleChatLoading, (store, payload) => payload);

// increase new message counter
guard({
  clock: updateChatContent,
  source: $drawer,
  filter: (drawer, newMessage) =>
    !newMessage?.message.payload && !(drawer.section === 'chat' && drawer.opened),
  target: plusNewMessage,
});

sample({
  clock: joinChat.doneData,
  target: updateLocalChatUser,
});
