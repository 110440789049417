import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7413835e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "panel" }
const _hoisted_2 = { class: "video-wrap" }
const _hoisted_3 = {
  key: 2,
  class: "layout-wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isMobileScreen)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 0,
          class: "icon",
          size: "l",
          mode: "flat",
          icon: { spriteUrl: _ctx.link, name: _ctx.settingsIcon, color: "--sui-gray-700" },
          disabled: false,
          iconOnly: "",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleDrawer('settings')))
        }, null, 8, ["icon"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Button, {
        class: "icon border",
        size: "l",
        mode: "flat",
        icon: { spriteUrl: _ctx.link, name: _ctx.iconMicName, color: "--sui-gray-700" },
        disabled: _ctx.isAudioDisabled,
        iconOnly: "",
        onClick: _ctx.toggleAudio
      }, null, 8, ["icon", "disabled", "onClick"]),
      _createVNode(_component_Button, {
        class: "icon border",
        size: "l",
        mode: "flat",
        icon: { spriteUrl: _ctx.link, name: _ctx.iconCamName, color: "--sui-gray-700" },
        disabled: _ctx.devices.videoDisabled,
        iconOnly: "",
        onClick: _ctx.toggleVideo
      }, null, 8, ["icon", "disabled", "onClick"])
    ]),
    (_ctx.isMobileScreen)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 1,
          class: "icon",
          size: "l",
          mode: "flat",
          icon: { spriteUrl: _ctx.link, name: _ctx.moreIcon, color: "--sui-gray-700" },
          disabled: false,
          iconOnly: "",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleDrawer('generalSettings')))
        }, null, 8, ["icon"]))
      : _createCommentVNode("", true),
    (!_ctx.isMobileScreen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_Tooltip, {
            class: "icon",
            text: _ctx.t('layout.change')
          }, {
            button: _withCtx(() => [
              _createVNode(_component_Button, {
                size: "l",
                mode: "flat",
                icon: { spriteUrl: _ctx.link, name: _ctx.layoutsIcon, color: "--sui-gray-700" },
                disabled: false,
                iconOnly: "",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleDrawer('changeLayout')))
              }, null, 8, ["icon"])
            ]),
            _: 1
          }, 8, ["text"])
        ]))
      : _createCommentVNode("", true)
  ]))
}