import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28349dda"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "button-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Typography = _resolveComponent("Typography")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Button, {
      class: "back-btn",
      icon: { spriteUrl: '/image/videoconf-icons.svg', name: 'ic25-shape'},
      size: "s",
      mode: "flat",
      onClick: _ctx.goHome
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t('back')), 1)
      ]),
      _: 1
    }, 8, ["icon", "onClick"]),
    _createVNode(_component_Typography, {
      class: "header-title",
      fontType: "header",
      fontColor: "--gray900"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t('join.mobileTitle')), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Button, {
        icon: { spriteUrl: '/image/videoconf-icons.svg', name: _ctx.deviceMicrophoneIcon, height: '25px', width: '25px'},
        size: "l",
        mode: "flat",
        onClick: _ctx.openAudioDeviceChoose
      }, null, 8, ["icon", "onClick"]),
      (_ctx.showCameraRotate)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            icon: { spriteUrl: '/image/videoconf-icons.svg', name: 'ic24-camera-rotate', height: '25px', width: '25px'},
            size: "l",
            mode: "flat",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cameraRotate()))
          }, null, 8, ["icon"]))
        : _createCommentVNode("", true)
    ])
  ]))
}