import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ffb23de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "assessment" }
const _hoisted_2 = { class: "star-block" }
const _hoisted_3 = { class: "star" }
const _hoisted_4 = { class: "under-stars-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typography = _resolveComponent("Typography")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Typography, {
      fontSize: "16px",
      fontColor: "var(--sui-gray-800)"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t('feedback.assessment.description')), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (n) => {
        return _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Icon, {
            spriteUrl: "/image/videoconf-icons.svg",
            name: "star",
            width: "64",
            height: "38",
            color: _ctx.starColor[n],
            onClick: ($event: any) => (_ctx.assess(n)),
            onMouseover: ($event: any) => (_ctx.assessHover(n)),
            onMouseleave: _ctx.clearAccess
          }, null, 8, ["color", "onClick", "onMouseover", "onMouseleave"])
        ])
      }), 64))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_Typography, {
        fontSize: "16px",
        fontColor: "var(--sui-gray-600)"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('feedback.assessment.bad')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_Typography, {
        fontSize: "16px",
        fontColor: "var(--sui-gray-600)"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('feedback.assessment.good')), 1)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_Button, {
      class: "submit-button",
      width: "fill-container",
      disabled: _ctx.disabled,
      onClick: _ctx.submitAssessment
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t('feedback.assessment.submit')), 1)
      ]),
      _: 1
    }, 8, ["disabled", "onClick"])
  ]))
}