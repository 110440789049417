
  import { defineComponent, ref, watch } from 'vue';
  import { Icon } from '@voximplant/spaceui';
  import { useI18n } from 'vue-i18n';

  export default defineComponent({
    name: 'Info',
    components: {
      Icon,
    },

    props: {
      isShow: {
        type: Boolean,
        default: false,
        required: false,
      },
      link: {
        type: String,
        default: '/image/videoconf-icons.svg',
        required: false,
      },
      iconName: {
        type: String,
        default: 'ic36-info-fill-blue',
        required: false,
      },
      errorInfo: {
        type: String,
        required: false,
      },
    },
    setup(props) {
      const { t } = useI18n();
      let title = ref(t('error.default.title'));
      let text = ref(t('error.default.description'));
      watch(props, () => {
        switch (props.errorInfo) {
          case 'browserPermission':
            title.value = t('error.browserPermission.title');
            text.value = t('error.browserPermission.description');
            return { title, text };
          case 'noCamera':
            title.value = t('error.noCamera.title');
            text.value = t('error.noCamera.description');
            return { title, text };
          case 'AbortError':
            title.value = t('error.abortError.title');
            text.value = t('error.abortError.description');
            return { title, text };
        }
      });
      return {
        title,
        text,
      };
    },
  });
