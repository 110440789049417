
  import { computed, defineComponent } from 'vue';
  import { Button, Typography } from '@voximplant/spaceui';
  import { openDrawer } from '@/store/drawer';
  import { VideoDeviceInfo } from '@/store/devices/VideoDeviceInfo';
  import { $mirrorStore, requestMirrorStream } from '@/store/mirrorMedia';
  import { $devices, setActiveDevices } from '@/store/devices';
  import { useStore } from 'effector-vue/composition.cjs';
  import router from '@/router';
  import { useI18n } from 'vue-i18n';

  export default defineComponent({
    name: 'MobileSettingsHeader',
    components: {
      Button,
      Typography,
    },
    setup() {
      const { t } = useI18n();
      const mirrorStore = useStore($mirrorStore);
      const devices = useStore($devices);
      const goHome = () => {
        router.replace({
          name: 'Signin',
        });
      };
      const isBluetoothAudioDevice = computed(() =>
        devices.value.selectedAudioDevice?.label.includes('Bluetooth')
      );
      const deviceMicrophoneIcon = computed(() =>
        isBluetoothAudioDevice.value ? 'ic25-bluetooth' : 'ic25-deviceMicrophone'
      );
      const openAudioDeviceChoose = () => {
        openDrawer({ section: 'chooseDevices', options: { type: 'audioDevices' } });
      };
      const cameraRotate = () => {
        if (devices.value.videoDevices?.length === 2) {
          const secondDevice: VideoDeviceInfo | any = devices.value.videoDevices.find(
            (device) => device.value !== devices.value.selectedVideoDevice?.value
          );
          if (mirrorStore.value.videoPreview && secondDevice) {
            requestMirrorStream({
              selectedAudioDevice: $devices.getState().selectedAudioDevice,
              selectedVideoDevice: secondDevice,
            });
          } else {
            setActiveDevices({ selectedVideoDevice: secondDevice });
          }
        } else {
          openDrawer({ section: 'chooseDevices', options: { type: 'videoDevices' } });
        }
      };
      const showCameraRotate = computed(
        () => devices.value.videoDevices && devices.value.videoDevices.length > 1
      );

      return {
        t,
        goHome,
        showCameraRotate,
        deviceMicrophoneIcon,
        cameraRotate,
        openAudioDeviceChoose,
      };
    },
  });
