
  import { defineComponent, ref } from 'vue';
  import { Button, Typography, Popup, Icon } from '@voximplant/spaceui';
  import { useI18n } from 'vue-i18n';

  export default defineComponent({
    name: 'Assessment',
    components: { Button, Typography, Popup, Icon },
    emits: ['update:assessment'],
    setup(props, { emit }) {
      const { t } = useI18n();
      const starColor = ref([
        'var(--sui-gray-200)',
        'var(--sui-gray-200)',
        'var(--sui-gray-200)',
        'var(--sui-gray-200)',
        'var(--sui-gray-200)',
        'var(--sui-gray-200)',
      ]);
      const disabled = ref(true);
      const assessed = ref(-1);
      const assess = (e: number) => {
        assessed.value = e;
        disabled.value = false;
        for (let i = 1; i <= 6; i++) {
          if (e >= i) starColor.value[i] = 'var(--sui-purple-500)';
          else starColor.value[i] = 'var(--sui-gray-200)';
        }
      };
      const assessHover = (e: number) => {
        for (let i = 1; i <= 6; i++) {
          if (e >= i) starColor.value[i] = 'var(--sui-purple-500)';
          else if (e >= assessed.value) starColor.value[i] = 'var(--sui-gray-200)';
        }
      };
      const clearAccess = () => {
        for (let i = 1; i <= 6; i++) {
          if (i > assessed.value) starColor.value[i] = 'var(--sui-gray-200)';
        }
      };
      const submitAssessment = () => {
        emit('update:assessment');
      };

      return {
        t,
        starColor,
        disabled,
        assess,
        assessHover,
        clearAccess,
        submitAssessment,
      };
    },
  });
