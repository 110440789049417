import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4469aa00"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "screen-settings" }
const _hoisted_2 = { class: "screen-wrap" }
const _hoisted_3 = { class: "screen" }
const _hoisted_4 = {
  key: 0,
  class: "video-container videoSlot"
}
const _hoisted_5 = { class: "footer" }
const _hoisted_6 = { class: "footer-button-wrap" }
const _hoisted_7 = { class: "setting-wrap" }
const _hoisted_8 = { class: "join-button-wrap" }
const _hoisted_9 = {
  key: 1,
  class: "waiting-text-wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileSettingsHeader = _resolveComponent("MobileSettingsHeader")!
  const _component_Info = _resolveComponent("Info")!
  const _component_UserVideoMock = _resolveComponent("UserVideoMock")!
  const _component_VideoSlotLabel = _resolveComponent("VideoSlotLabel")!
  const _component_UserList = _resolveComponent("UserList")!
  const _component_ButtonPanel = _resolveComponent("ButtonPanel")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Typography = _resolveComponent("Typography")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isMobilePlatform)
      ? (_openBlock(), _createBlock(_component_MobileSettingsHeader, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Info, {
          class: "info-screen",
          isShow: !!_ctx.deviceError,
          errorInfo: _ctx.deviceError
        }, null, 8, ["isShow", "errorInfo"]),
        (!_ctx.deviceError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("video", {
                class: "video",
                style: _normalizeStyle({display: _ctx.mirrorStore.videoPreview ? 'block' : 'none'}),
                ref: "mirrorVideo",
                autoplay: "true",
                playsinline: ""
              }, null, 4),
              (!_ctx.mirrorStore.videoPreview)
                ? (_openBlock(), _createBlock(_component_UserVideoMock, {
                    key: 0,
                    class: "mock",
                    avatar: _ctx.userAvatar,
                    ref: "userVideoMockElement",
                    size: _ctx.videoSlotSize,
                    name: _ctx.auth.displayName
                  }, null, 8, ["avatar", "size", "name"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_VideoSlotLabel, {
          class: "name",
          name: _ctx.auth.displayName,
          showButton: false
        }, null, 8, ["name"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.isMobileScreen)
        ? (_openBlock(), _createBlock(_component_UserList, { key: 0 }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_ButtonPanel, { deviceError: _ctx.deviceError }, null, 8, ["deviceError"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          (_ctx.meeting.needRequestToJoin !== _ctx.StatusOfRequest.processing)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                class: "button-setting",
                mode: "primary",
                disabled: _ctx.isJoinDisable,
                onClick: _ctx.goNext
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.meeting.needRequestToJoin === _ctx.StatusOfRequest.needRequest ? _ctx.t("join.requestToJoinBtn") : _ctx.t("join.btn")), 1)
                ]),
                _: 1
              }, 8, ["disabled", "onClick"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(_component_Typography, { class: "title" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('join.waiting.title')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_Typography, { class: "secondary" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('join.waiting.text')), 1)
                  ]),
                  _: 1
                })
              ]))
        ])
      ])
    ])
  ]))
}