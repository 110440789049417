
  import Logo from '@/components/Logo.vue';
  import { defineComponent, ref } from 'vue';
  import ScreenSettings from '@/components/settings/ScreenSettings.vue';
  import ExpandedSettings from '@/components/settings/ExpandedSettings.vue';
  import { useIsMobileScreen } from '@/hooks/isMobile';
  import { closeDrawer, $drawer } from '@/store/drawer';
  import ChangeLayout from '@/components/ChangeLayout.vue';
  import Drawer from '@/components/Drawer.vue';
  import MenuSettings from '@/components/settings/MenuSettings.vue';
  import MobileExpandedSettings from '@/components/settings/MobileExpandedSettings.vue';
  import MobileDownloadFile from '@/components/settings/MobileDownloadFile.vue';
  import { useStore } from 'effector-vue/composition.cjs';
  import { Popup, Button, Typography } from '@voximplant/spaceui';
  import Assessment from '@/components/feedback/Assessment.vue';
  import Feedback from '@/components/feedback/Feedback.vue';
  import { $popup, closePopup } from '@/store/popup';
  import { useI18n } from 'vue-i18n';
  import ChooseDevices from '@/components/ChooseDevices.vue';
  import UserList from '@/components/settings/UserList.vue';
  import router from '@/router';
  import { JwtStorage } from '@/api';
  import { resetAuthStore, toggleTokenNotValid } from '@/store/auth';
  import UsersInTheMeeting from '@/components/chat/UsersInTheMeeting.vue';

  export default defineComponent({
    name: 'SettingsInDefault',
    components: {
      Logo,
      ScreenSettings,
      UserList,
      ExpandedSettings,
      ChangeLayout,
      UsersInTheMeeting,
      ChooseDevices,
      Drawer,
      MenuSettings,
      MobileExpandedSettings,
      MobileDownloadFile,
      Popup,
      Button,
      Assessment,
      Feedback,
      Typography,
    },
    props: {
      deviceError: {
        type: String,
        default: '',
        required: false,
      },
      conference: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const { t } = useI18n();
      const { isMobileScreen } = useIsMobileScreen();
      const drawerStore = useStore($drawer);
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const allowDevicesImg = require('@/assets/images/allowDevicesImg.jpg');
      const popup = useStore($popup);
      const showAllUser = ref(false);
      const showExpandedUserList = () => {
        showAllUser.value = !showAllUser.value;
      };
      const reloadPage = () => {
        location.reload();
      };
      const close = () => {
        closePopup();
        closeDrawer();
      };
      const routeToAuth = () => {
        // call when user token not valid
        JwtStorage.deleteToken(); // delete token in local storage
        resetAuthStore(); // set NoAuth state and delete user info
        sessionStorage.setItem('backLink', props.conference);
        router
          .replace({
            name: 'Signin', // if the user token is not valid
          })
          .finally(() => {
            closePopup();
            toggleTokenNotValid(false); // reset disable approve/dismiss btn flag
          });
      };

      return {
        popup,
        close,
        reloadPage,
        isMobileScreen,
        t,
        drawerStore,
        allowDevicesImg,
        showAllUser,
        showExpandedUserList,
        routeToAuth,
      };
    },
  });
