
  import { computed, defineComponent, onMounted, ref } from 'vue';
  import { Button, Typography } from '@voximplant/spaceui';
  import Info from '@/components/info/Info.vue';
  import ButtonPanel from '@/components/settings/ButtonPanel.vue';
  import UserVideoMock from '@/components/layouts/UserVideoMock.vue';
  import { $devices } from '@/store/devices/index';
  import { useStore } from 'effector-vue/composition';
  import { meetingStore, requestJoinMeeting, startMeeting, StatusOfRequest } from '@/store/meeting';
  import { $confWSConnected, authStore } from '@/store/auth';
  import { $mirrorStore } from '@/store/mirrorMedia/index';
  import { useI18n } from 'vue-i18n';
  import { $users } from '@/store/users';
  import VideoSlotLabel from '@/components/layouts/VideoSlotLabel.vue';
  import { getVideoSlotSize } from '@/helpers/getVideoSlotSize';
  import { useIsMobilePlatform, useIsMobileScreen } from '@/hooks/isMobile';
  import MobileSettingsHeader from '@/components/settings/MobileSettingsHeader.vue';
  import UserList from '@/components/settings/UserList.vue';

  const __default__ = defineComponent({
    name: 'ScreenSettings',
    components: {
      Button,
      Typography,
      Info,
      ButtonPanel,
      VideoSlotLabel,
      UserVideoMock,
      MobileSettingsHeader,
      UserList,
    },

    props: {
      isShow: {
        type: Boolean,
        default: true,
      },
      deviceError: {
        type: String,
        default: '',
        required: false,
      },
    },
    setup(props) {
      const { isMobilePlatform } = useIsMobilePlatform();
      const { isMobileScreen } = useIsMobileScreen();
      const mirrorVideo = ref<HTMLVideoElement>();
      const meeting = useStore(meetingStore);
      const auth = useStore(authStore);
      const devices = useStore($devices);
      const { t } = useI18n();
      const mirrorStore = useStore($mirrorStore);
      const alreadyJoin = ref(false);
      const users = useStore($users);
      const userAvatar = computed(() => users.value.me?.authUser?.avatar);
      const confWSConnected = useStore($confWSConnected);
      const isJoinDisable = computed(
        () =>
          (!!props.deviceError && props.deviceError !== 'noCamera') ||
          !mirrorStore.value.audioPreview ||
          alreadyJoin.value ||
          !auth.value.sdkConnected || // eliminates the error: reading iceServers properties of undefined"
          !confWSConnected.value // allow send /request-join only after opening conf websocket
      );
      const userVideoMockElement = ref<InstanceType<typeof UserVideoMock> | null>(null);
      const videoSlotSize = computed(() =>
        userVideoMockElement.value?.$el
          ? getVideoSlotSize({
              width: userVideoMockElement.value?.$el.clientWidth,
              height: userVideoMockElement.value?.$el.clientHeight,
            })
          : 'medium'
      );
      onMounted(() => {
        $mirrorStore.watch((state) => {
          if (state.videoPreview && mirrorVideo.value) {
            mirrorVideo.value.srcObject = new MediaStream([state.videoPreview]);
            mirrorVideo.value?.addEventListener('canplaythrough', () => mirrorVideo.value?.play());
          }

          if (!state.videoPreview && mirrorVideo.value) {
            mirrorVideo.value.srcObject = null;
          }
        });
      });

      startMeeting.pending.watch((isActive) => {
        if (isActive) {
          alreadyJoin.value = true;
        }
      });
      const gridTemplateRows = computed(() => (isMobilePlatform ? '52px 1fr 261px' : '1fr 261px'));

      const goNext = async () => {
        if (meeting.value.needRequestToJoin === StatusOfRequest.needRequest) {
          if (meeting.value.meetingId) {
            await requestJoinMeeting(meeting.value.meetingId);
          }
        } else {
          alreadyJoin.value = true;
          await startMeeting([
            meeting.value.meetingId as string,
            meeting.value.voxMeetingId as string,
          ]);
        }
      };

      return {
        t,
        isJoinDisable,
        goNext,
        auth,
        devices,
        mirrorVideo,
        mirrorStore,
        userAvatar,
        userVideoMockElement,
        videoSlotSize,
        isMobilePlatform,
        isMobileScreen,
        meeting,
        StatusOfRequest,
        gridTemplateRows,
      };
    },
  });

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "6bc71db2": (_ctx.gridTemplateRows)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__