import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-408dd755"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "feedback-tab" }
const _hoisted_2 = {
  key: 0,
  class: "items"
}
const _hoisted_3 = {
  key: 1,
  class: "text-input"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typography = _resolveComponent("Typography")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Typography, {
      class: "header",
      fontType: "header",
      fontSize: "500"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t('feedback.category.other.description')), 1)
      ]),
      _: 1
    }),
    (_ctx.items.items.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.refItems, (item) => {
            return (_openBlock(), _createBlock(_component_Checkbox, {
              label: item.value,
              id: item.id,
              size: "l",
              "onUpdate:modelValue": ($event: any) => (_ctx.checked($event, item)),
              modelValue: item.isChecked
            }, null, 8, ["label", "id", "onUpdate:modelValue", "modelValue"]))
          }), 256))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_Textarea, {
            placeholder: _ctx.t('feedback.category.other.placeholder'),
            modelValue: _ctx.userComments,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userComments) = $event))
          }, null, 8, ["placeholder", "modelValue"])
        ])),
    _createVNode(_component_Button, {
      class: "submit-button",
      width: "fill-container",
      disabled: _ctx.disabled,
      onClick: _ctx.submitFeedback
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t('feedback.submit')), 1)
      ]),
      _: 1
    }, 8, ["disabled", "onClick"])
  ]))
}